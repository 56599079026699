import React, { useEffect, useState } from "react";
import AllNavbar from "../components/common/AllNavBar";
import AboutUs from "../components/common/page-componets/AboutUs";
import Vision from "../components/common/page-componets/Vision";
import { useDispatch, useSelector } from "react-redux";
import { staffsList } from "../action/serviceAction";
import { BASE_IP } from "../env";
import Modal from "../components/common/Modal";
// import Modal from "../components/common/Modal"; // Assuming you have a Modal component

const About = () => {
  const dispatch = useDispatch();
  const { staff, error } = useSelector((state) => state.StaffList);
  const [loading, setLoading] = useState(true);
  const [selectedMember, setSelectedMember] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(staffsList());
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  if (loading) {
    return <div className="text-center py-20">Loading...</div>;
  }

  if (error) {
    return (
      <div className="text-center py-20 text-red-500">Error loading data!</div>
    );
  }

  return (
    <>
      <AllNavbar />

      {/* Gradient Background Section */}
      <div className="bg-gradient-to-b from-blue-400 to-white py-8 mt-20">
        <div className="text-center mb-10">
          <h2 className="text-4xl font-extrabold text-teal-600 mb-4">
            About Us
          </h2>
        </div>
      </div>

      <div className="px-[3%] md:px-[6%] mt-8 lg:mt-2">
        <AboutUs />

        {/* Company Information Section */}
        <div className="bg-gray-50 py-16 mt-16 px-[3%] md:px-[6%]">
          <div className="max-w-5xl mx-auto text-gray-800">
            <h2 className="text-5xl font-extrabold text-center text-teal-600 mb-8">
              Borikof Trading Share Company
            </h2>

            <p className="text-lg mb-6 leading-relaxed text-gray-700">
              As the name Borikof implies from its Oromic root,
              <span className="italic text-teal-700">
                {" "}
                “for my tomorrow / for my descendants”
              </span>
              , the company was established to set a profound foundation for
              tomorrow.
            </p>

            <p className="text-lg mb-6 leading-relaxed text-gray-700">
              Borikof Trading Share Company is a business organization with full
              legal standing, established by a team of 172 members in 2012 EC.
              The company focuses on addressing significant community gaps, such
              as education, public health, and supplies.
            </p>

            <div className="bg-teal-100 p-6 rounded-lg shadow-md mb-8">
              <h3 className="text-3xl font-bold text-teal-600 mb-4">
                Borikof Nur Academy
              </h3>
              <p className="text-lg text-gray-700">
                Borikof Nur Academy, a product of Borikof Trading Share Company,
                was established to provide quality education and fill
                educational gaps in the community.
              </p>
            </div>

            <div className="border-t-4 border-teal-500 my-8"></div>

            <p className="text-lg font-semibold mb-4 text-teal-600">
              Management Structure:
            </p>
            <ul className="list-disc pl-5 mb-6 space-y-2 text-gray-700">
              <li>A Board and monitoring board</li>
              <li>A CEO</li>
              <li>An HR in-charge</li>
              <li>An Accountant / Financial Manager</li>
              <li>A Store In-charge</li>
              <li>44 employees working at the kindergarten</li>
              <li>53 employees at the primary school</li>
              <li>8 employees at the central office</li>
            </ul>

            <div className="bg-gray-100 p-6 rounded-lg shadow-md mb-8">
              <h3 className="text-3xl font-bold text-teal-600 mb-4">
                Legal Framework
              </h3>
              <p className="text-lg text-gray-700">
                From a legal standpoint, Borikof operates as a share company,
                respecting employees' and shareholders' rights, and paying all
                forms of governmental and social taxes.
              </p>
            </div>

            <div className="border-t-4 border-teal-500 my-8"></div>

            <p className="text-lg leading-relaxed text-gray-700">
              Borikof is committed to maintaining its legal and ethical
              obligations while continually striving to provide high-quality
              education and services to the community.
            </p>
          </div>
        </div>
        <Vision />
      </div>

      {/* Team Members Section */}
      <div className="bg-yellow-50 py-12 mt-12">
        <h2 className="text-5xl font-extrabold text-center text-gray-800 mb-6">
          Meet Our Administrative Team
        </h2>
        <p className="text-center text-gray-700 mb-8 px-8 lg:px-60">
          Our team is composed of dedicated professionals who are passionate
          about providing the best service possible. Each member brings a unique
          set of skills and expertise, making us a diverse and dynamic group.
        </p>

        {/* Administrators Section */}
        <div className="bg-gray-50 py-12">
          <h3 className="text-4xl font-bold text-center text-gray-800 mb-8">
            BORIKOF TRADING SHARE COMPANY LIST OF BOARD MEMBERS{" "}
          </h3>
          <div className="flex flex-wrap justify-center px-4 md:px-8 gap-8">
            {staff
              ?.filter((member) => member.employment_postion === "boardMembers")
              .map((member) => (
                <div
                  key={member.id}
                  className="bg-white shadow-lg rounded-lg p-6 w-72 text-center transition-transform transform hover:scale-105 hover:shadow-2xl"
                >
                  <img
                    className="w-28 h-28 rounded-full mx-auto mb-4 border-4 border-teal-400 transition-transform transform hover:scale-110"
                    src={`${member.profile_picture}`}
                    alt={`${member.first_name} ${member.last_name}`}
                  />
                  <h3 className="text-3xl font-semibold text-gray-800 mb-2">{`${member.first_name} ${member.last_name}`}</h3>
                  <p className="text-gray-600 text-lg mb-4">{member.role}</p>
                  <p className="text-gray-700 leading-relaxed mb-4">
                    {member.bio}
                  </p>
                  <button
                    className="mt-2 px-4 py-2 bg-teal-500 text-white rounded hover:bg-teal-600 transition"
                    onClick={() => {
                      setSelectedMember(member);
                      setModalOpen(true);
                    }}
                  >
                    View Profile
                  </button>
                </div>
              ))}
          </div>
        </div>

        {/* Staff Section */}
        {/* <div className="bg-gray-50 py-12 mt-12">
          <h3 className="text-4xl font-bold text-center text-gray-800 mb-8">
            Staff Members
          </h3>
          <div className="flex flex-wrap justify-center px-4 md:px-8 gap-8">
            {staff
              ?.filter((member) => member.role === "staff")
              .map((member) => (
                <div
                  key={member.id}
                  className="bg-white shadow-lg rounded-lg p-6 w-72 text-center transition-transform transform hover:scale-105 hover:shadow-2xl"
                >
                  <img
                    className="w-28 h-28 rounded-full mx-auto mb-4 border-4 border-gray-400 transition-transform transform hover:scale-110"
                    src={`${member.profile_picture}`}
                    alt={`${member.first_name} ${member.last_name}`}
                  />
                  <h3 className="text-3xl font-semibold text-gray-800 mb-2">{`${member.first_name} ${member.last_name}`}</h3>
                  <p className="text-gray-600 text-lg mb-4">{member.role}</p>
                  <p className="text-gray-700 leading-relaxed mb-4">
                    {member.bio}
                  </p>
                  <button
                    className="mt-2 px-4 py-2 bg-teal-500 text-white rounded hover:bg-teal-600 transition"
                    onClick={() => {
                      setSelectedMember(member);
                      setModalOpen(true);
                    }}
                  >
                    View Profile
                  </button>
                </div>
              ))}
          </div>
        </div> */}

        {/* board members Section */}
        <div className="bg-gray-50 py-12 mt-12">
          <h3 className="text-4xl font-bold text-center text-gray-800 mb-8">
            MANAGEMENT TEAM{" "}
          </h3>
          <div className="flex flex-wrap justify-center px-4 md:px-8 gap-8">
            {staff
              ?.filter(
                (member) => member.employment_postion === "managementTeam"
              )
              .map((member) => (
                <div
                  key={member.id}
                  className="bg-white shadow-lg rounded-lg p-6 w-72 text-center transition-transform transform hover:scale-105 hover:shadow-2xl"
                >
                  <img
                    className="w-28 h-28 rounded-full mx-auto mb-4 border-4 border-gray-400 transition-transform transform hover:scale-110"
                    src={`${member.profile_picture}`}
                    alt={`${member.first_name} ${member.last_name}`}
                  />
                  <h3 className="text-3xl font-semibold text-gray-800 mb-2">{`${member.first_name} ${member.last_name}`}</h3>
                  <p className="text-gray-600 text-lg mb-4">{member.role}</p>
                  <p className="text-gray-700 leading-relaxed mb-4">
                    {member.bio}
                  </p>
                  <button
                    className="mt-2 px-4 py-2 bg-teal-500 text-white rounded hover:bg-teal-600 transition"
                    onClick={() => {
                      setSelectedMember(member);
                      setModalOpen(true);
                    }}
                  >
                    View Profile
                  </button>
                </div>
              ))}
          </div>
        </div>
      </div>

      {/* Modal for Profile Viewing */}
      {isModalOpen && selectedMember && (
        <Modal member={selectedMember} onClose={() => setModalOpen(false)} />
      )}
    </>
  );
};

export default About;
